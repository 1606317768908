import { Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';
import React from 'react';

export function UTMField() {
  const record = useRecordContext() || {};
  const { utm } = record;
  return (
    <ul>
      {Object.entries(utm).map(([key, value]) => (
        <li key={key}>
          <Typography fontSize={12}>{`${key}: ${value}`}</Typography>
        </li>
      ))}
    </ul>
  );
}

UTMField.defaultProps = {
  label: 'UTM',
};
