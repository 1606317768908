import { Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';
import React from 'react';

export function SourceField() {
  const record = useRecordContext() || {};
  const { utm, leadReferrer } = record;
  return <Typography fontSize={12}>{([leadReferrer, utm?.source].filter(Boolean).join(' / ')).toUpperCase()}</Typography>;
}

SourceField.defaultProps = {
  label: 'Source',
};
